import React, { createContext, useState, useEffect } from "react";

const DeviceContext = createContext({
  isMobileDevice: false,
  isTabletDevice: false,
  width: 1440,
});

const DeviceProvider = ({ children }) => {
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [isTabletDevice, setIsTabletDevice] = useState(false);
  const [width, setWidth] = useState();

  const checkScreenWidth = () => {
    if (document.body.clientWidth >= 1440) {
      setIsMobileDevice(false);
      setIsTabletDevice(false);
    } else if (
      document.body.clientWidth >= 600 &&
      document.body.clientWidth < 1440
    ) {
      setIsMobileDevice(false);
      setIsTabletDevice(true);
    } else if (document.body.clientWidth < 600) {
      setIsMobileDevice(true);
      setIsTabletDevice(false);
    }
    setWidth(Math.max(document.body.clientWidth));
  };

  useEffect(() => {
    checkScreenWidth();
    window.addEventListener("resize", checkScreenWidth);
    document.addEventListener("resize", checkScreenWidth);
    return () => {
      window.removeEventListener("resize", checkScreenWidth);
      document.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  return (
    <DeviceContext.Provider value={{ isMobileDevice, isTabletDevice, width }}>
      {children}
    </DeviceContext.Provider>
  );
};

export { DeviceContext, DeviceProvider };
