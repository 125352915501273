// react imports
import React, { useState } from "react";

// third-party imports

// in-app imports

export default (props) => {
  const {
    buttonLabel = "",
    onClick,
    buttonType = "primary",
    buttonSize = "md",
    variant = "round",
    icon = "",
    iconShow = icon,
    iconAlignment = "right",
    altText = "",
    extraClass = "",
    submitType = "undefined",
    isDisabled = false,
    textSize,
    minWidth = "",
    height = ""
  } = props;

  const buttonStyles = {
    minWidth: minWidth,
    height: height,
  };

  return (
    <button
      className={`s-button s-button--${buttonType} s-button--${buttonSize} s-button--${variant} ${extraClass} s-button__icon--${iconAlignment}`}
      type={submitType}
      onClick={onClick}
      disabled={isDisabled}
      style={buttonStyles}
    >
      <span
        className={`s-button__text--${buttonSize}`}
        style={{ fontSize: textSize }}
      >
        {buttonLabel}
      </span>
      {icon && (
        <img
          className={`s-button__icon--${buttonSize} ${
            iconShow ? "" : "s-button--hide"
          }`}
          src={icon}
          alt={altText}
        />
      )}
    </button>
  );
};
